export const validateEmail = (email) => {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
}

export const calculateBill = (BillableServices)=>{
    let totalTransactionCharges = 0
    let totalNFTCharges = 0
    let totalTokenCharges = 0
    const numberofTransactions = BillableServices[0].transactionUsageCounter
    if (numberofTransactions <= charges.transactions[0].threshold) {
        totalTransactionCharges = charges.transactions[0].price * numberofTransactions
    }
    else if (numberofTransactions > charges.transactions[0].threshold && numberofTransactions <= charges.transactions[1].threshold) {
        //remove the first threshold from the number of transactions
        const numberofTransactionsAfterThreshold = numberofTransactions - charges.transactions[0].threshold
        totalTransactionCharges = charges.transactions[0].price * charges.transactions[0].threshold + charges.transactions[1].price * numberofTransactionsAfterThreshold
    }
    else if (numberofTransactions > charges.transactions[1].threshold && numberofTransactions <= charges.transactions[2].threshold) {
        //remove the first two thresholds from the number of transactions
        const numberofTransactionsAfterThreshold = numberofTransactions - charges.transactions[0].threshold - charges.transactions[1].threshold
        totalTransactionCharges = charges.transactions[0].price * charges.transactions[0].threshold + charges.transactions[1].price * charges.transactions[1].threshold + charges.transactions[2].price * numberofTransactionsAfterThreshold
    }
    else if (numberofTransactions > charges.transactions[2].threshold && numberofTransactions <= charges.transactions[3].threshold) {
        //remove the first three thresholds from the number of transactions
        const numberofTransactionsAfterThreshold = numberofTransactions - charges.transactions[0].threshold - charges.transactions[1].threshold - charges.transactions[2].threshold
        totalTransactionCharges = charges.transactions[0].price * charges.transactions[0].threshold + charges.transactions[1].price * charges.transactions[1].threshold + charges.transactions[2].price * charges.transactions[2].threshold + charges.transactions[3].price * numberofTransactionsAfterThreshold
    }
    else if (numberofTransactions > charges.transactions[3].threshold && numberofTransactions <= charges.transactions[4].threshold) {
        //remove the first four thresholds from the number of transactions
        const numberofTransactionsAfterThreshold = numberofTransactions - charges.transactions[0].threshold - charges.transactions[1].threshold - charges.transactions[2].threshold - charges.transactions[3].threshold
        totalTransactionCharges = charges.transactions[0].price * charges.transactions[0].threshold + charges.transactions[1].price * charges.transactions[1].threshold + charges.transactions[2].price * charges.transactions[2].threshold + charges.transactions[3].price * charges.transactions[3].threshold + charges.transactions[4].price * numberofTransactionsAfterThreshold
    }
    else if (numberofTransactions > charges.transactions[4].threshold && numberofTransactions <= charges.transactions[5].threshold) {
        //remove the first five thresholds from the number of transactions
        const numberofTransactionsAfterThreshold = numberofTransactions - charges.transactions[0].threshold - charges.transactions[1].threshold - charges.transactions[2].threshold - charges.transactions[3].threshold - charges.transactions[4].threshold
        totalTransactionCharges = charges.transactions[0].price * charges.transactions[0].threshold + charges.transactions[1].price * charges.transactions[1].threshold + charges.transactions[2].price * charges.transactions[2].threshold + charges.transactions[3].price * charges.transactions[3].threshold + charges.transactions[4].price * charges.transactions[4].threshold + charges.transactions[5].price * numberofTransactionsAfterThreshold
    }


    // NFT Charges
    const numberofNFT = BillableServices[0].nftUsageCounter
    if (numberofNFT <= charges.nft[0].threshold) {
        totalNFTCharges = charges.nft[0].price * numberofNFT
    }
    else if (numberofNFT > charges.nft[0].threshold && numberofNFT <= charges.nft[1].threshold) {
        //remove the first threshold from the number of transactions
        const numberofNFTAfterThreshold = numberofNFT - charges.nft[0].threshold
        totalNFTCharges = charges.nft[0].price * charges.nft[0].threshold + charges.nft[1].price * numberofNFTAfterThreshold
    }
    else if (numberofNFT > charges.nft[1].threshold && numberofNFT <= charges.nft[2].threshold) {
        //remove the first two thresholds from the number of transactions
        const numberofNFTAfterThreshold = numberofNFT - charges.nft[0].threshold - charges.nft[1].threshold
        totalNFTCharges = charges.nft[0].price * charges.nft[0].threshold + charges.nft[1].price * charges.nft[1].threshold + charges.nft[2].price * numberofNFTAfterThreshold
    }
    else if (numberofNFT > charges.nft[2].threshold && numberofNFT <= charges.nft[3].threshold) {
        //remove the first three thresholds from the number of transactions
        const numberofNFTAfterThreshold = numberofNFT - charges.nft[0].threshold - charges.nft[1].threshold - charges.nft[2].threshold
        totalNFTCharges = charges.nft[0].price * charges.nft[0].threshold + charges.nft[1].price * charges.nft[1].threshold + charges.nft[2].price * charges.nft[2].threshold + charges.nft[3].price * numberofNFTAfterThreshold
    }
    else if (numberofNFT > charges.nft[3].threshold && numberofNFT <= charges.nft[4].threshold) {
        //remove the first four thresholds from the number of transactions
        const numberofNFTAfterThreshold = numberofNFT - charges.nft[0].threshold - charges.nft[1].threshold - charges.nft[2].threshold - charges.nft[3].threshold
        totalNFTCharges = charges.nft[0].price * charges.nft[0].threshold + charges.nft[1].price * charges.nft[1].threshold + charges.nft[2].price * charges.nft[2].threshold + charges.nft[3].price * charges.nft[3].threshold + charges.nft[4].price * numberofNFTAfterThreshold
    }
    else if (numberofNFT > charges.nft[4].threshold && numberofNFT <= charges.nft[5].threshold) {
        //remove the first five thresholds from the number of transactions
        const numberofNFTAfterThreshold = numberofNFT - charges.nft[0].threshold - charges.nft[1].threshold - charges.nft[2].threshold - charges.nft[3].threshold - charges.nft[4].threshold
        totalNFTCharges = charges.nft[0].price * charges.nft[0].threshold + charges.nft[1].price * charges.nft[1].threshold + charges.nft[2].price * charges.nft[2].threshold + charges.nft[3].price * charges.nft[3].threshold + charges.nft[4].price * charges.nft[4].threshold + charges.nft[5].price * numberofNFTAfterThreshold
    }

    //token charges
    const numberofTokens = BillableServices[0].tokenUsageCounter
    if (numberofTokens <= charges.tokens[0].threshold) {
        totalTokenCharges = charges.tokens[0].price * numberofTokens
    }
    else if (numberofTokens > charges.tokens[0].threshold && numberofTokens <= charges.tokens[1].threshold) {
        //remove the first threshold from the number of transactions
        const numberofTokensAfterThreshold = numberofTokens - charges.tokens[0].threshold
        totalTokenCharges = charges.tokens[0].price * charges.tokens[0].threshold + charges.tokens[1].price * numberofTokensAfterThreshold
    }
    else if (numberofTokens > charges.tokens[1].threshold && numberofTokens <= charges.tokens[2].threshold) {
        //remove the first two thresholds from the number of transactions
        const numberofTokensAfterThreshold = numberofTokens - charges.tokens[0].threshold - charges.tokens[1].threshold
        totalTokenCharges = charges.tokens[0].price * charges.tokens[0].threshold + charges.tokens[1].price * charges.tokens[1].threshold + charges.tokens[2].price * numberofTokensAfterThreshold
    }
    else if (numberofTokens > charges.tokens[2].threshold && numberofTokens <= charges.tokens[3].threshold) {
        //remove the first three thresholds from the number of transactions
        const numberofTokensAfterThreshold = numberofTokens - charges.tokens[0].threshold - charges.tokens[1].threshold - charges.tokens[2].threshold
        totalTokenCharges = charges.tokens[0].price * charges.tokens[0].threshold + charges.tokens[1].price * charges.tokens[1].threshold + charges.tokens[2].price * charges.tokens[2].threshold + charges.tokens[3].price * numberofTokensAfterThreshold
    }
    else if (numberofTokens > charges.tokens[3].threshold && numberofTokens <= charges.tokens[4].threshold) {
        //remove the first four thresholds from the number of transactions
        const numberofTokensAfterThreshold = numberofTokens - charges.tokens[0].threshold - charges.tokens[1].threshold - charges.tokens[2].threshold - charges.tokens[3].threshold
        totalTokenCharges = charges.tokens[0].price * charges.tokens[0].threshold + charges.tokens[1].price * charges.tokens[1].threshold + charges.tokens[2].price * charges.tokens[2].threshold + charges.tokens[3].price * charges.tokens[3].threshold + charges.tokens[4].price * numberofTokensAfterThreshold
    }
    else if (numberofTokens > charges.tokens[4].threshold && numberofTokens <= charges.tokens[5].threshold) {
        //remove the first five thresholds from the number of transactions
        const numberofTokensAfterThreshold = numberofTokens - charges.tokens[0].threshold - charges.tokens[1].threshold - charges.tokens[2].threshold - charges.tokens[3].threshold - charges.tokens[4].threshold
        totalTokenCharges = charges.tokens[0].price * charges.tokens[0].threshold + charges.tokens[1].price * charges.tokens[1].threshold + charges.tokens[2].price * charges.tokens[2].threshold + charges.tokens[3].price * charges.tokens[3].threshold + charges.tokens[4].price * charges.tokens[4].threshold + charges.tokens[5].price * numberofTokensAfterThreshold
    }

    //total charges
    const totalCharges = totalTransactionCharges + totalNFTCharges + totalTokenCharges
    return {
        id: BillableServices[0].id,
        nftUsage: BillableServices[0].nftUsageCounter,
        nftUsagePrice: totalNFTCharges,
        transactionUsage: BillableServices[0].transactionUsageCounter,
        transactionUsagePrice: totalTransactionCharges,
        tokenUsage: BillableServices[0].tokenUsageCounter,
        tokenUsagePrice: totalTokenCharges,
        policyUsage: BillableServices[0].policyUsageCounter,
        foundationUsage: BillableServices[0].foundationUsageCounter,
        totalUsagePrice: totalCharges,
      };
}

export const charges ={
    transactions: [
        {
            tier: 'Tier 1',
            price: 0.5,
            threshold: 999
        },
        {
            tier: 'Tier 2',
            price: 0.035,
            threshold: 1000
        },
        {
            tier: 'Tier 3',
            price: 0.025,
            threshold: 10000
        },
        {
            tier: 'Tier 4',
            price: 0.02,
            threshold: 100000
        },
        {
            tier: 'Tier 5',
            price: 0.02,
            threshold: 1000000
        },
        {
            tier: 'Tier 6',
            price: 0.02,
            threshold: 1000000
        }
    ],
    nft : [
        {
            tier: 'Tier 1',
            price: 2,
            threshold: 99
        },
        {
            tier: 'Tier 2',
            price: 1,
            threshold: 100
        },
        {
            tier: 'Tier 3',
            price: 0.5,
            threshold: 1000
        },
        {
            tier: 'Tier 4',
            price: 0.1,
            threshold: 10000
        },
        {
            tier: 'Tier 5',
            price: 0.05,
            threshold: 100000
        },
        {
            tier: 'Tier 6',
            price: 0.01,
            threshold: 1000000
        }
    ],
    tokens: [
        {
            tier: 'Tier 1',
            price: 2,
            threshold: 99
        },
        {
            tier: 'Tier 2',
            price: 1,
            threshold: 100
        },
        {
            tier: 'Tier 3',
            price: 0.5,
            threshold: 1000
        },
        {
            tier: 'Tier 4',
            price: 0.1,
            threshold: 10000
        },
        {
            tier: 'Tier 5',
            price: 0.05,
            threshold: 100000
        },
        {
            tier: 'Tier 6',
            price: 0.01,
            threshold: 1000000
        }
    ]
}