import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import './TopBar.css'
import Logo from "../../assets/logo.png"
import SideNav from '../SideNav/SideNav'
import {
  AppBar,
  Toolbar,
  Avatar,
  Menu,
  MenuItem,
  Tooltip,
  IconButton,
  Button,
  Select,
  OutlinedInput,
  Dialog,
  DialogActions
} from '@mui/material'
import { Timestamp } from 'firebase/firestore'
import { getAuth } from 'firebase/auth'
import { toast, ToastContainer } from 'react-toastify'
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate } from 'react-router-dom'
import { FaAlignLeft, FaQuestionCircle, FaTimes } from 'react-icons/fa'
import { signOut } from '../../store/actions/AuthActions'
import { addOrg, getOrgs, setCurrentOrg } from '../../store/actions/ManageOrgsActions'
import GooglePlacesAutocomplete from "react-google-places-autocomplete";


export const TopBar = () => {

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [openAdd, setOpenAdd] = useState(false)
  const greaterThan1200 = useMediaQuery("(min-width:1200px)");
  const [openDrawer, setOpenDrawer] = useState(true)
  const [name, setName] = useState('')
  const [taxId, setTaxId] = useState('')
  const [phone, setPhone] = useState('')
  const [street, setStreet] = useState('')
  const [country, setCountry] = useState('')
  const [zip, setZip] = useState('')
  const [city, setCity] = useState('')
  const [state, setState] = useState('')
  const [check, setCheck] = useState(false)
  const dispatch = useDispatch()
  const orgs = useSelector((state) => state.ManageOrgsList.orgs)
  const currentOrg = useSelector((state) => state.ManageOrgsList.currentOrg)
  const [loader, setLoader] = useState(true)
  const [address, setAddress] = useState("");
  useEffect(() => {
    if (address?.value?.terms.at(-1)?.value) {
      setCountry(address?.value?.terms.at(-1).value)
    }
    if (address?.value?.terms.at(-2)?.value) {
      setState(address?.value?.terms.at(-2).value)
    }
    if (address?.value?.terms.at(-3)?.value) {
      setCity(address?.value?.terms.at(-3).value)
    }
    if (address?.value?.terms.at(-5)?.value && address?.value?.terms.at(-4)?.value) {
      setStreet(address?.value?.terms.at(-5).value + " " + address?.value?.terms.at(-4).value)
    }
  }, [address]);

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 300,
        width: 450,
      },
    },
  };
  useEffect(() => {
    setOpenDrawer(greaterThan1200)
  }, [greaterThan1200])
  useEffect(() => {
    dispatch(getOrgs())
      .then(() => setLoader(false))
  }, [dispatch])
  useEffect(() => {
    if (!loader) {
      if (orgs.length > 0) {
        setOpenAdd(false)
      }
      else {
        setOpenAdd(true)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgs])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const nav = useNavigate()
  const handelSignOut = () => {
    handleClose()
    dispatch(signOut())
  }
  const setSelectedOrg = (id) => {
    const org = orgs.filter(org => org.id === id)
    dispatch(setCurrentOrg(org[0].id))
  }
  const addOrganization = () => {
    if (name.trim().length < 1) {
      toast.warning('Organization Name should not be empty')
    }
    else if (taxId.trim().length < 1) {
      toast.warning('Organization Tax Id Should not be empty')
    }
    else if (street.trim().length < 1) {
      toast.warn('Street should not be empty')
    }
    else if (city.trim().length < 1) {
      toast.warn('City should not be empty')
    }
    else if (state.trim().length < 1) {
      toast.warn('State should not be empty')
    }
    // else if (zip.trim().length !== 6) {
    //   toast.warn('Zip should be 6 digits.')
    // }
    else if (country.trim().length < 1) {
      toast.warn('Country should not be empty')
    }

    else if (phone.length < 10) {
      toast.warn('Organization Phone number should be 10 digits')
    }
    else if (!check) {
      toast.warn('Please agree to Terms & Conditions')
    }
    else {
      const auth = getAuth()
      const user = auth.currentUser
      var body = {
        name: name,
        created_by: user.uid,
        owned_by: user.uid,
        tax_id: taxId,
        validated: 'new',
        created_at: Timestamp.now(),
        phone: phone,
        street: street,
        city: city,
        state: state,
        country: country,
        zip: zip,
        members: [
          {
            id: user.uid,
            role: 'Owner',
            displayName: user.displayName || user.email,
          }
        ]
      }
      dispatch(addOrg(body))
      setOpenAdd(false)
      setName('')
      setTaxId('')
      setPhone('')
      setStreet('')
      setCity('')
      setState('')
      setCountry('United States of America')
      setZip('')
      setCheck(false)
    }
  }

  return (
    <>
      <ToastContainer limit={3} />
      <AppBar title="TopBar" position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: 'white', boxShadow: `0px 0px 15px rgba(2, 27, 121, 0.2)` }}>
        <Toolbar variant='dense'>
          <div className='tool-bar'>
            <div className='tool-bar-left'>
              <div className="bar-icon">
                {
                  greaterThan1200 ? null :
                    <IconButton onClick={() => setOpenDrawer(!openDrawer)}>
                      {
                        openDrawer ? <FaTimes color='#0575E6' size={24} /> : <FaAlignLeft color='#0575E6' size={24} />
                      }
                    </IconButton>
                }
              </div>
              <div className="logo-div">
                <img src={Logo} alt="logo" className="logo-img" />
              </div>
            </div>
            <div className='tool-bar-right'>
              <Select
                className='company-select'
                value={currentOrg.org ? currentOrg.org.name : "No Organization Selected"}
                input={<OutlinedInput />}
                MenuProps={MenuProps}
              >
                <Button className='auth-btn' fullWidth onClick={() => setOpenAdd(true)}>Add Organization</Button>
                <MenuItem disabled value="">
                  <em>Change Organization </em>
                </MenuItem>
                <MenuItem disabled sx={{ display: 'none' }} value="No Organization Selected">
                  <em>No Organization Selected </em>
                </MenuItem>
                {orgs.map((org) => (
                  <MenuItem
                    sx={{
                      height: 50
                    }}
                    key={org.name}
                    value={org.name}
                    onClick={() => setSelectedOrg(org.id)}
                  >
                    {org.name}
                  </MenuItem>
                ))}
              </Select>
              <Tooltip title="View API Doc">
                <IconButton onClick={() => nav('/docs/public-api/v1')}>
                  <FaQuestionCircle color='#0575E6' />
                </IconButton>
              </Tooltip>
              <Avatar sx={{ width: 56, height: 56, backgroundColor: '#0575E6' }} onClick={handleClick} />
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <MenuItem onClick={() => handelSignOut()}>Logout</MenuItem>
              </Menu>
            </div>
          </div>
        </Toolbar>
      </AppBar>
      <SideNav open={openDrawer} />
      <>
        <Dialog open={openAdd} className='individual-dialog'>
          <div className='add-org-container'>
            <div className='add-org-container'>
              <span>
                Create organization
              </span>
              <div className='text-field-container'>
                <input placeholder='Organization name' type="text" value={name} onChange={(e) => { setName(e.target.value) }} className='add-org-input full-width' />
                <input placeholder='Organization TIN' type="text" value={taxId} onChange={(e) => { setTaxId(e.target.value) }} className='add-org-input full-width' />
                <GooglePlacesAutocomplete
                  apiKey={process.env.REACT_APP_GOOGLE_PLACES}
                  selectProps={{
                    styles: {
                      dropdownIndicator: (provided, state) => {
                        return {
                          ...provided,
                          color: "blue"
                        };
                      },
                      clearIndicator: (provided, state) => {
                        return {
                          ...provided,
                          color: "blue"
                        };
                      },
                      control: (provided, state) => {
                        return {
                          ...provided,
                          borderRadius: "15px",
                          border: "1px solid #EEEEEE",
                          margin: "5px",
                          width: "100%"
                        };
                      }
                    },
                    isClearable: true,
                    minLengthAutocomplete: 3,
                    placeholder: 'Search Address',
                    debounce: 1000,
                    value: address,
                    onChange: (val) => {
                      setAddress(val);
                    }
                  }}
                />
                <div className="form-group">
                  <input type="text"
                    id="autocomplete"
                    placeholder="Street"
                    value={street}
                    onChange={(e) => setStreet(e.target.value)}
                    className=' form-control add-org-input' />

                  <input type="text"
                    id="inputCity"
                    placeholder="City"
                    onChange={(e) => setCity(e.target.value)}
                    value={city}
                    className='add-org-input form-control' />

                  <input type="text"
                    id="inputState"
                    placeholder="State"
                    onChange={(e) => setState(e.target.value)}
                    value={state}
                    className='add-org-input form-control' />

                  <input type="number"
                    id="inputZip"
                    placeholder="Zip"
                    onChange={(e) => setZip(e.target.value)}
                    value={zip}
                    className='add-org-input form-control' />

                  <input type="text"
                    id="inputCounty"
                    placeholder="County"
                    onChange={(e) => setCountry(e.target.value)}
                    value={country}
                    className='add-org-input form-control' />
                </div>

                <input placeholder='Organization phone Number' type='tel' value={phone} onChange={(e) => { setPhone(e.target.value) }} className='add-org-input full-width' />
                <p className='helper-text'>Taxpayer Identification Number (TIN) is a nine-digit number that always begins with the number 9 and is in the following format: 9xx-xx-xxxx.</p>
              </div>
              <div className='terms-container'>
                <input type="checkbox" checked={check} onChange={(e) => { setCheck(!check) }} />
                <p className='helper-text'>I hereby accept the Terms of Service. For more information about Blockery's Terms of Service, please visit our <a href='https://blockery.io/terms-of-service'>Terms of Service</a> page.</p>
              </div>
            </div>
          </div>
          <DialogActions>
            <Button className='dialog-submit-btn' onClick={() => addOrganization()}>Submit</Button>
            {
              orgs.length > 0 ? <Button className='dialog-cancel-btn' onClick={() => setOpenAdd(false)}>Close</Button> : <Button className='dialog-cancel-btn-red' onClick={() => handelSignOut()}>Sign Out</Button>
            }
          </DialogActions>
        </Dialog>
      </>
    </>
  )
}

export default TopBar