import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  GithubAuthProvider,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  updateProfile,
  sendEmailVerification,
  sendPasswordResetEmail,
  getAdditionalUserInfo,
} from "firebase/auth";
import { doc, updateDoc, getFirestore } from "firebase/firestore";
import axios from "axios";
import { toast } from "react-toastify";
import ReactGA from 'react-ga4'

export const login = (body) => {
  return async (dispatch) => {
    try {
      const auth = getAuth();
      const res = await signInWithEmailAndPassword(
        auth,
        body.email,
        body.password,
      );
      await sendDataToGA(res.user);
      dispatch({
        type: "LoginSuccess",
        payload: res.user,
      });
    } catch (e) {
      dispatch({
        type: "LoginError",
        payload: e,
      });
    }
  };
};
export const signUp = (body) => {
  return async (dispatch) => {
    try {
      const auth = getAuth();
      const res = await createUserWithEmailAndPassword(
        auth,
        body.email,
        body.password,
      );
      await sendDataToGA(res);
      try {
        await updateProfile(res.user, {
          displayName: body.name,
        });
      } catch (e) {
        console.log(e);
      }
      await sendEmailVerification(res.user);
      dispatch({
        type: "LoginSuccess",
        payload: res.user,
      });
    } catch (e) {
      dispatch({
        type: "LoginError",
        payload: e,
      });
    }
  };
};
export const forgot = (body) => {
  return async (dispatch) => {
    try {
      const auth = getAuth();
      await sendPasswordResetEmail(auth, body.email);
      toast.success("Email sent successfully");
      setTimeout(() => {
        window.location.href = "/login";
      }, 2000);
    } catch (e) {
      console.log(e);
      toast.error("Email not sent");
    }
  };
};
export const signOut = () => {
  return async (dispatch) => {
    try {
      const auth = getAuth();
      dispatch({
        type : "SignOutPending"
      })
      await auth.signOut();
      dispatch({
        type : "SignOut"
      })
    } catch (e) {
      dispatch({
        type: "LogoutError",
        payload: e,
      });
    }
  };
};
export const connectWithGoogle = () => {
  return async (dispatch) => {
    try {
      const provider = new GoogleAuthProvider();
      const auth = getAuth();
      signInWithPopup(auth, provider).then(async (result) => {
        await sendDataToGA(result);
        dispatch({
          type: "LoginSuccess",
          payload: result.user,
        });
      });
    } catch (e) {
      dispatch({
        type: "LoginError",
        payload: e,
      });
    }
  };
};
export const connectWithGithub = () => {
  return async (dispatch) => {
    try {
      const provider = new GithubAuthProvider();
      const auth = getAuth();
      signInWithPopup(auth, provider).then(async (result) => {
        await sendDataToGA(result);
        dispatch({
          type: "LoginSuccess",
          payload: result.user,
        });
      });
    } catch (e) {
      dispatch({
        type: "LoginError",
        payload: e,
      });
    }
  };
};
export const generateToken = (orgId, name) => {
  return async (dispatch) => {
    dispatch({
      type: "Loading",
      payload: true,
    });
    const auth = getAuth();
    auth.currentUser.getIdToken(true).then(async (token) => {
      axios
        .post(
          `${process.env.REACT_APP_ORIGIN}/generateCustomToken`,
          {
            orgId,
            name,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        )
        .then(async (res) => {
          dispatch({
            type: "SetToken",
            payload: {
              token: res.data,
            },
          });
          dispatch({
            type: "Loading",
            payload: false,
          });
        });
    });
  };
};
export const revokeToken = (id) => {
  return async () => {
    try {
      const ref = await doc(getFirestore(), "tokens", id);
      await updateDoc(ref, {
        status: "revoked",
      });
    } catch (e) {
      toast.error("Error while revoking token");
    }
  };
};
export const revokePubNubToken = (id) => {
  return async () => {
    const auth = getAuth();
    auth.currentUser.getIdToken(true).then(async (token) => {
      let config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      axios.post(
        `${process.env.REACT_APP_ORIGIN}/revoke-pubnub-token`,
        {
          id,
        },
        config,
      );
    });
  };
};

const sendDataToGA = async (result) => {
  const details = getAdditionalUserInfo(result);
  if (details.isNewUser) {
    ReactGA.set({ userId:  result.user.uid });
    ReactGA.event({
      category: 'sign_up',
      action: 'sign_up',
    })
    // ReactGA.event("sign_up",)
 
    // const tagManagerArgs = {
    //   gtmId: "GTM-N4HGQD7", //move this to .env
    // };

    // TagManager.initialize(tagManagerArgs);
    // await window.dataLayer.push({
    //   event: "sign_up",
    //   userId: result.user.uid,
    // });
  }
};
