import React, { Component } from 'react'
import BlockeryLogo from '../../assets/logoDark.png'
import './Invoice.css'

class Invoice extends Component {
    constructor(props) {
        super(props)
        this.state = {
            owner: '',
            invoice :  this.props.invoice.invoice,
            currentOrg : this.props.invoice.currentOrg
        }
    }
    componentDidMount = () => {
        document.title = "Blockery Web App || Invoice"
        const owner = this.props.invoice.currentOrg.members.filter(
            member => member.role === 'Owner'
        )
        this.setState({ owner: owner[0].displayName })
    }
    render() {
        return (
            <div className="invoice-box">
                <table>
                    <tbody>
                        <tr className="top">
                            <td colSpan="3">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td className="title">
                                                <img src={BlockeryLogo} alt="Company logo" style={{ width: '100%', maxWidth: '100px' }} />
                                            </td>
                                            <td>
                                                Invoice Id: {this.state.invoice.id}<br />
                                                {/* Created: January 1, 2015<br />
                                                Due: February 1, 2015 */}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr className="information">
                            <td colSpan="3">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <b>Bill From</b> <br />
                                                Blockery Inc.<br />
                                                San Francisco, <br />
                                                California (USA)
                                            </td>
                                            <td>
                                                <b>Bill To</b> <br />
                                                {this.state.currentOrg.name}<br />
                                                {this.state.currentOrg.address} <br/>
                                                {this.state.currentOrg.phone} <br/>
                                                {this.state.owner}<br />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr className="heading">
                            <td>Payment Method</td>
                            <td>Status</td>
                            <td></td>

                        </tr>
                        <tr className="details">
                            <td>ADA</td>
                            <td>Paid</td>
                            <td></td>
                        </tr>
                        <tr className="heading">
                            <td>Item</td>
                            <td>Quantity</td>
                            <td>Price</td>
                        </tr>
                        <tr className="item">
                            <td>NFT Request</td>
                            <td>{this.state.invoice.nftUsage ||0}</td>
                            <td>${this.state.invoice.nftUsagePrice || 0}</td>
                        </tr>
                        <tr className="item">
                            <td>Token Request</td>
                            <td>{this.state.invoice.tokenUsage ||0}</td>
                            <td>${this.state.invoice.tokenUsagePrice || 0}</td>
                        </tr>
                        <tr className="item last">
                            <td>Transaction Request</td>
                            <td>{this.state.invoice.transactionUsage || 0}</td>
                            <td>${this.state.invoice.transactionUsagePrice || 0}</td>
                        </tr>
                        <tr className="item last">
                            <td>Policy Request</td>
                            <td>{this.state.invoice.policyUsage || 0}</td>
                            <td>$0</td>
                        </tr>
                        <tr className="item last">
                            <td>Foundation Registration Request (Create / Update)</td>
                            <td>{this.state.invoice.foundationUsage || 0}</td>
                            <td>$0</td>
                        </tr>
                        <tr className="total">
                            <td></td>
                            <td></td>
                            <td>Total: ${this.state.invoice.totalUsagePrice || 0}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    }
}

const ComponentToPrint = React.forwardRef((props, ref) => {
    return (
        <Invoice ref={ref} invoice={props} />
    );
});
export default ComponentToPrint