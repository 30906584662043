import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { acceptInvitation } from "../../store/actions/ManageOrgsActions";
import { ToastContainer } from 'react-toastify';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

export const AcceptInvitation = ({ state,acceptInvitationStore }) => {
  const [open, setOpen] = useState(false);
  const { inviteToken } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    if (!state.isAuthenticated) {
      window.sessionStorage.setItem("inviteToken", inviteToken);
      navigate("/")
    } else {
      setOpen(true);
    }
  }, [state.isAuthenticated,inviteToken,navigate]);
  const acceptInvitation =()=>{
    let uid =state.user.uid;
    acceptInvitationStore({inviteToken,uid});
    window.sessionStorage.removeItem("inviteToken");
    setOpen(false);
    setTimeout(()=>{
      navigate("/dashboard");
    },2000)
  }
  const rejectInvitation=()=>{
      setOpen(false);
      window.sessionStorage.removeItem("inviteToken");
      navigate("/dashboard");
  }
  return (
    <>
    <ToastContainer/>
      {state.isAuthenticated ? (
        <Dialog open={open}>
          <DialogTitle>Accept Invitation</DialogTitle>
          <DialogContent>
            <DialogContentText>
              You have been invited to join the team. Please Click Accept to
              join the team.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={acceptInvitation}>Accept</Button>
            <Button onClick={rejectInvitation}>Reject</Button>
          </DialogActions>
        </Dialog>
      ) : (
        <CircularProgress />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    state: state.AuthServicesList,
  };
};

const mapDispatchToProps = {
    acceptInvitationStore : (hash)=>acceptInvitation(hash)
};

export default connect(mapStateToProps, mapDispatchToProps)(AcceptInvitation);
