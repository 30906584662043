import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
    Button,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    CircularProgress
} from '@mui/material'
import { getOrgById } from '../../store/actions/ManageOrgsActions'
import { generateToken } from '../../store/actions/AuthActions'
import { toast } from 'react-toastify'

export class AddAPIToken extends Component {
    constructor(props) {
        super(props)
        this.state = {
            open: false,
            tokenName: '',
            openTokenAPI: false
        }
    }
    handelGenerateToken = () => {
        this.props.generateToken(this.props.orgId, this.state.tokenName)
        this.setState({
            open: false,
            openTokenAPI: true,
            tokenName: '',
        })
        this.props.getOrgById(this.props.orgId)
    }
    copyTokenPubNub = () => {
        navigator.clipboard.writeText(this.props.state.token.token)
        toast.success('Token Copied to Clipboard')
    }
    closeTokenPopUpPubNub = () => {
        this.setState({
            openTokenAPI: false,
            tokenName: ''
        })
    }

    render() {
        return (
            <>
                <Button onClick={() => this.setState({ open: true })} className='individual-add-btn'>Add API Token +</Button>
                <Dialog open={this.state.open} className='individual-dialog'>
                    <DialogTitle>Generate API Token</DialogTitle>
                    <DialogContent>
                        <input className='auth-input' style={{ marginTop: "5px" }} placeholder='Add API token name' color='success' onChange={(e) => this.setState({ tokenName: e.target.value })} />
                        <p style={{ maxWidth: '400px' }}>Note: You can provide any name you like upto 15 characters. It will help you identify the token by name.</p>
                    </DialogContent>
                    <DialogActions>
                        <Button className='dialog-submit-btn' onClick={() => this.handelGenerateToken()}>Generate</Button>
                        <Button className='dialog-cancel-btn' onClick={() => this.setState({ open: false, tokenName: '' })}>Cancel</Button>
                    </DialogActions>
                </Dialog>
                <Dialog open={this.state.openTokenAPI}>
                    <DialogTitle>API Token Details</DialogTitle>
                    <DialogContent>
                        {
                            this.props.orgState.loading ?
                                <CircularProgress /> : <input readOnly className='auth-input' value={this.props.state.token.token}></input>
                        }
                        <p>Note: Please Keep these details safe & Secure. You can view this only once. Tokens remain valid until exclusively revoked</p>
                    </DialogContent>
                    <DialogActions>
                        <Button className='dialog-submit-btn' disabled={this.props.orgState.loading} onClick={() => this.copyTokenPubNub()}>Copy</Button>
                        <Button className='dialog-cancel-btn' onClick={() => this.closeTokenPopUpPubNub()}>Close</Button>
                    </DialogActions>
                </Dialog>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        state: state.AuthServicesList,
        orgState: state.ManageOrgsList
    }
}

const mapDispatchToProps = dispatch => ({
    generateToken: (orgId, name) => {
        dispatch(generateToken(orgId, name))
    },
    getOrgById: (id)=>{
        dispatch(getOrgById(id))
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(AddAPIToken)