import axios from "axios";
import { toast } from "react-toastify";

import {
  collection,
  query,
  where,
  addDoc,
  getDoc,
  getFirestore,
  doc,
  getDocs,
  updateDoc,
  arrayUnion,
  Timestamp,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { calculateBill } from "../../utils/utils";

export const addOrg = (body) => {
  return async (dispatch) => {
    try {
      const auth = getAuth();
      const user = auth.currentUser;
      body.walletAddress = "pending";
      body.walletId = "pending";
      body.created_at = Timestamp.now();
      body.updated_at = Timestamp.now();
      addDoc(collection(getFirestore(), "orgs"), body).then((res) => {
        toast.success("Organization Added Successfully", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        const userRef = doc(getFirestore(), `users/${user.uid}`);
        updateDoc(userRef, {
          orgs: arrayUnion(res.id),
        });
        dispatch(getOrgs(true));
      });
    } catch (e) {
      console.log(e);
      toast.error("Operation Failed", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      window.location.replace("/");
    }
  };
};
export const getOrgs = (isNew) => {
  return async (dispatch, getState) => {
    try {
      const auth = getAuth();
      auth.onAuthStateChanged(async (user) => {
        if (!user) return;
        var data = [];
        // get user where user.id = user.uuid
        const userRef = doc(getFirestore(), `users/${user.uid}`);
        const userDoc = await getDoc(userRef);
        if (userDoc.exists("orgs")) {
          for (let i = 0; i < userDoc.data().orgs.length; i++) {
            const orgRef = doc(
              getFirestore(),
              `orgs/${userDoc.data().orgs[i]}`,
            );
            const orgDoc = await getDoc(orgRef);
            data.push({
              id: orgDoc.id,
              ...orgDoc.data(),
            });
          }
        }
        dispatch({
          type: "GetSuccess",
          payload: data,
        });
        if (isNew) {
          dispatch(setCurrentOrg(data[data.length - 1].id));
          if (sessionStorage.getItem("inviteId")) {
            setTimeout(() => {
              sessionStorage.setItem("refOrg", data[data.length - 1].id);
            }, 3000);
          }
        } else {
          if (data.length) {
            dispatch(setCurrentOrg(data[0].id));
          }
        }
      });
    } catch (e) {
      console.log(e);
    }
  };
};
export const setCurrentOrg = (orgId) => {
  return async (dispatch) => {
    try {
      if (orgId) {
        dispatch(getOrgById(orgId));
      }
    } catch (err) {
      console.log(err);
    }
  };
};
export const invitation = (body) => {
  return async (dispatch, getState) => {
    const auth = getAuth();
    const user = auth.currentUser;
    // create invitation for user
    addDoc(collection(getFirestore(), "invites"), {
      orgId: body.orgId,
      userId: user.uid,
      status: "pending",
      email: body.email,
      origin: window.location.origin,
    }).then((res) => {
      toast.success("Invitation Sent Successfully", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
      });
    });
  };
};
export const acceptInvitation = (body) => {
  return async (dispatch, getState) => {
    try {
      const ref = await doc(getFirestore(), "invites", body.inviteToken);
      updateDoc(ref, {
        status: "accepted",
      }).then((res) => {
        getDoc(ref).then(async (res) => {
          const orgId = res.data().orgId;
          const orgref = await doc(getFirestore(), "orgs", orgId);
          const auth = getAuth();
          const user = auth.currentUser;
          updateDoc(orgref, {
            members: arrayUnion({
              id: user.uid,
              role: "member",
            }),
          });
          const userRef = doc(getFirestore(), `users/${user.uid}`);
          updateDoc(userRef, {
            orgs: arrayUnion(orgref.id),
          });
        });
      });
    } catch (e) {
      console.log(e);
    }
  };
};
export const getOrgById = (orgId) => {
  return async (dispatch) => {
    try {
      let tokens = [];
      const pubnubtokens = [];
      const ref = await doc(getFirestore(), "orgs", orgId);
      const q = query(
        collection(getFirestore(), "tokens"),
        where("orgId", "==", orgId),
      );
      getDocs(q).then((snapshot) => {
        snapshot.forEach((doc) => {
          tokens.push({ id: doc.id, ...doc.data() });
        });
      });
      const org = await getDoc(ref);
      const q2 = query(
        collection(getFirestore(), "pubnubtokens"),
        where("orgId", "==", orgId),
      );
      const pubnub = await getDocs(q2);
      pubnub.forEach((doc) => {
        pubnubtokens.push({
          id: doc.id,
          ...doc.data(),
        });
      });
      const data = {
        org: {
          id: org.id,
          ...org.data(),
        },
        tokens: tokens,
        pubnubtokens: pubnubtokens,
      };
      dispatch({
        type: "SetCurrentOrg",
        payload: data,
      });
      dispatch(getWalletByOrgId(orgId));
    } catch (err) {
      console.log(err);
    }
  };
};
export const getWalletByOrgId = (orgId) => {
  return async (dispatch) => {
    const auth = getAuth();
    auth.currentUser.getIdToken(true).then(async (token) => {
      axios
        .get(`${process.env.REACT_APP_ORIGIN}/wallet-details/${orgId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          dispatch({
            type: "WalletSuccess",
            payload: res.data,
          });
        })
        .catch((e) => {
          console.log(e);
        });
    });
  };
};
export const generateTokenPubNub = (body) => {
  return async (dispatch) => {
    dispatch({
      type: "Loading",
      payload: true,
    });
    const auth = getAuth();
    auth.currentUser.getIdToken(true).then(async (token) => {
      axios
        .post(
          `${process.env.REACT_APP_ORIGIN}/generate-token-for-pubnub`,
          body,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        )
        .then((res) => {
          dispatch({
            type: "TokenSuccess",
            payload: res.data,
          });
          dispatch({
            type: "Loading",
            payload: false,
          });
        })
        .catch((e) => {
          console.log(e);
        });
    });
  };
};
export const getBillableServices = (currentOrg) => {
  return async (dispatch) => {
    if (currentOrg) {
      const currentMonth = new Date().getMonth() + 1;
      const currentYear = new Date().getFullYear();
      let previousMonth = new Date().getMonth();
      let yearLastMonth = new Date().getFullYear();
      if (currentMonth === 1) {
        yearLastMonth--;
        previousMonth = 12;
      }
      const q = query(
        collection(getFirestore(), "billable_services"),
        where("org_id", "==", currentOrg.id),
        where("month", "==", currentMonth),
        where("year", "==", currentYear),
      );
      getDocs(q).then((snapshot) => {
        let bill = {};
        snapshot.forEach((snap) => {
          bill = snap.data();
        });
        const zeroArray = new Array(31).fill(0);
        for (let day in bill) {
          if (!isNaN(day)) {
            const index = parseInt(day);
            let totalUsageForDay = 0;
            if (bill[index]?.nftUsageCounter) {
              totalUsageForDay += bill[index]?.nftUsageCounter;
            }
            if (bill[index]?.tokenUsageCounter) {
              totalUsageForDay += bill[index]?.tokenUsageCounter;
            }
            if (bill[index]?.transactionUsageCounter) {
              totalUsageForDay += bill[index]?.transactionUsageCounter;
            }
            zeroArray[index - 1] = totalUsageForDay;
          }
        }
        bill.dayWiseUsage = zeroArray;
        dispatch({
          type: "CurrentBill",
          payload: bill,
        });
      });
      const q2 = query(
        collection(getFirestore(), "billable_services"),
        where("org_id", "==", "1"),
        where("month", "==", previousMonth),
        where("year", "==", yearLastMonth),
      );
      getDocs(q2).then((snapshot) => {
        let bill = {};
        snapshot.forEach((snap) => {
          bill = snap.data();
        });
        const zeroArray = new Array(31).fill(0);
        for (let day in bill) {
          if (!isNaN(day)) {
            const index = parseInt(day);
            let totalUsageForDay = 0;
            if (bill[index]?.nftUsageCounter) {
              totalUsageForDay += bill[index]?.nftUsageCounter;
            }
            if (bill[index]?.tokenUsageCounter) {
              totalUsageForDay += bill[index]?.tokenUsageCounter;
            }
            if (bill[index]?.transactionUsageCounter) {
              totalUsageForDay += bill[index]?.transactionUsageCounter;
            }
            zeroArray[index - 1] = totalUsageForDay;
          }
        }
        bill.dayWiseUsage = zeroArray;
        dispatch({
          type: "PreviousBill",
          payload: bill,
        });
      });
    }
  };
};
export const billing = (month, year, currentOrg) => {
  return async (dispatch) => {
    if (currentOrg.org) {
      const q = query(
        collection(getFirestore(), "billable_services"),
        where("org_id", "==", currentOrg.org.id),
        where("month", "==", month),
        where("year", "==", year),
      );
      getDocs(q).then((snapshot) => {
        let bill = {};
        snapshot.forEach((snap) => {
          bill = snap.data();
          bill.id = snap.id;
        });
        let currentMonth = new Date().getMonth() + 1;
        let currentYear = new Date().getFullYear();
        if (month === currentMonth && year === currentYear) {
          if (snapshot.empty) {
            console.log("no bill found");
          } else {
            const data = snapshot.docs.map(doc =>{
              const data = {
                  id: doc.id,
                  ...doc.data()
              }
              return data
            })
            const invoice = calculateBill(data);
            dispatch({
              type: "invoice",
              payload: invoice,
            });
          }
        }
        else{
          const invoice = {
            id: bill.id,
            nftUsage: bill.nftUsageCounter,
            nftUsagePrice: bill.nftUsagePrice,
            transactionUsage: bill.transactionUsageCounter,
            transactionUsagePrice: bill.transactionUsagePrice,
            tokenUsage: bill.tokenUsageCounter,
            tokenUsagePrice: bill.tokenUsagePrice,
            policyUsage: bill.policyUsageCounter,
            foundationUsage: bill.foundationUsageCounter,
            totalUsagePrice: bill.totalUsagePrice,
          };
          dispatch({
            type: "invoice",
            payload: invoice,
          });
        }
      });
    }
  };
};

export const EditOrgRequest = (body) => {
  return async (dispatch) => {
    body.status = "pending";
    body.created_at = Timestamp.now();
    body.updated_at = Timestamp.now();
    addDoc(collection(getFirestore(), "org_update_request"), body)
      .then((res) => {
        toast.success("Request Added Successfully");
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong");
      });
  };
};
export const FindEditOrgRequest = (id) => {
  return async (dispatch) => {
    const q = query(
      collection(getFirestore(), "org_update_request"),
      where("org_id", "==", id),
      where("status", "==", "pending"),
    );
    return getDocs(q).then((res) => res.empty);
  };
};
