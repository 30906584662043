import React, {useEffect} from 'react'
import './NotFound.css'
import NotFoundSvg from '../../assets/404.svg'
import  {Button} from '@mui/material'
import {useNavigate} from 'react-router'

function NotFound() {
    const nav =useNavigate()
    useEffect(()=>{
      document.title ="Blockery Web App || Not Found"
  })
  return (
    <div className='not-found-container'>
        <img src={NotFoundSvg}  alt='Not Found'/>
        <span className='not-found-subtitle'>Sorry, it looks like the page get lost</span>
        <Button className='auth-btn' onClick={()=>nav('/')}>Back to Home</Button>
    </div>
  )
}

export default NotFound