import React, { Component } from 'react'
import { connect } from 'react-redux'
import { 
    Button, 
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent
} from '@mui/material'
import { invitation } from '../../store/actions/ManageOrgsActions'

export class AddMember extends Component {
    constructor(props){
        super(props)
        this.state={
            open: false,
            email: false
        }
    }
    sendInvitation = () => {
        this.props.invitation({
            orgId: this.props.orgId,
            email: this.state.email,
            orgName: this.props.orgName
        })
        this.setState({
            email: '',
            open: false
        })
    }
  render() {
    return (
        <>
        <Button onClick={()=>this.setState({open: true})}  className='individual-add-btn'>Add Member +</Button> 
        <Dialog open={this.state.open} className="individual-dialog">
                        <DialogTitle>Invite Member to organization</DialogTitle>
                        <DialogContent style={{display : 'flex', flexDirection : 'column'}}>
                            Invite to organization
                            <input className="auth-input" placeholder='Enter Email' onChange={(e) => this.setState({ email: e.target.value })} />
                        </DialogContent>
                        <DialogActions>
                            <Button className='dialog-submit-btn' onClick={()=>this.sendInvitation()}>Invite</Button>
                            <Button className='dialog-cancel-btn' onClick={() => this.setState({ open: false,  email : '' })}>Cancel</Button>
                        </DialogActions>
                    </Dialog>
        </>
    )
  }
}

const mapStateToProps = state => {
    return{

    }
}

const mapDispatchToProps = dispatch =>({
    invitation: (body) => {
        dispatch(invitation(body))
    },

})

export default connect(mapStateToProps, mapDispatchToProps)(AddMember)