import {useEffect} from 'react'
import ReactGA from 'react-ga4'
import { useLocation } from 'react-router'

export default function ReactGAComponent() {
    const location = useLocation()
    useEffect(()=>{
        ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID, {testMode:false})
        ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
    },[location.pathname, location.search])
  return null
}
