import React, { Component } from 'react'
import { RedocStandalone } from 'redoc';
import PublicApi from './public_api_swagger.yaml'


export default class ReDoc extends Component {
    constructor(props) {
        super(props);
        this.state = {
            spec: '',
        }
    }
    componentDidMount (){
        document.title ="Blockery Web App || Docs"
    }
    render() {
        return (
            <RedocStandalone specUrl={PublicApi} 
                options={{
                    expandResponses: 'all',
                    nativeScrollbars: true,
                    theme: { colors: { primary: { main: '#0575E6' } } },
                }}
            />
        )
    }
}
