import React, { Component } from 'react'
import {Button} from '@mui/material'
import { Navigate } from 'react-router'
import AuthSVG from '../../assets/auth-right.svg'
import BlockeryBanner from '../../assets/blockery-banner-dark.png'

export default class AuthRight extends Component {
constructor(props) {
    super(props)
    this.state = {
        navigate: false
    }
}
navToDocs=() =>{
    this.setState({
        navigate: true
    })
}

  render() {
    return (
      <>
      {
            this.state.navigate ? <Navigate to="/docs/public-api/v1" /> : null
      }
      <div className='auth-container-right' >
                <div className='text-block'>
                    <img data-aos="fade-right" data-aos-duration="1000"  style={{ width: 550 }} src={BlockeryBanner} alt='logo' />
                    <div>
                        <p data-aos="fade-right" data-aos-duration="1000">The New Standard In Blockchain Integration</p>
                        <div data-aos="fade-in" data-aos-duration="1000" className="learn-more-btn-container">
                        <Button  className='learn-more-btn' onClick={() => window.open('https://blockery.io', "_blank")}> Learn More</Button>
                        <Button  className='learn-more-btn' onClick={() => this.navToDocs()}> API Doc</Button>
                        </div>
                    </div>
                </div>
            </div>
            <img data-aos="fade-in" data-aos-duration="1000" src={AuthSVG} className="auth-right-svg" alt='auth-right-svg'/>
      </>
    )
  }
}
