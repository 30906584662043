import React, { useRef, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import Invoice from './Invoice'
import ReactToPrint from 'react-to-print';
import TopBar from '../TopBar/TopBar';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  MenuItem,
  OutlinedInput,
  FormControl,
  InputLabel
} from '@mui/material';
import { FaEye, FaPen } from 'react-icons/fa';
import { billing } from '../../store/actions/ManageOrgsActions'
import './Bill.css'

function Billing() {
  const componentRef = useRef();
  const [open, setOpen] = useState(false)
  const [dateOpen, setDateOpen] = useState(false)
  const [selectedMonth, setSelectedMonth] = useState(0)
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear())
  const [monthAfterSubmit, setMonthAfterSubmit] = useState('')
  const [yearAfterSubmit, setYearAfterSubmit] = useState('')
  const dispatch = useDispatch()
  const currentOrg = useSelector((state) => state.ManageOrgsList.currentOrg)
  const invoice = useSelector((state) => state.ManageOrgsList.invoice)
  const currentMonth = new Date().getMonth()
  let currentYear = new Date().getFullYear()
  var months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  var years = [2022 , 2023]
  const handleChangeMonth = (e) => {
    setSelectedMonth(e.target.value)
  }
  const handleChangeYear = (e) => {
    setSelectedYear(e.target.value)
  }
  const handleSubmit = () => {
    dispatch(billing(selectedMonth + 1, selectedYear, currentOrg))
    setMonthAfterSubmit(selectedMonth)
    setYearAfterSubmit(selectedYear)
    setDateOpen(false)
  }
  useEffect(() => {
    dispatch(billing(currentMonth + 1, currentYear, currentOrg))
    setMonthAfterSubmit(currentMonth)
    setYearAfterSubmit(currentYear)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrg])

  return (
    <div>
      <TopBar />
      <div className='bill-page-container'>
        <div className='bill-top'>
          <span>Billing</span>
          <div className='date-container'>
            <span style={{marginRight: 5}}>{months[monthAfterSubmit]}</span>
            <span>{yearAfterSubmit}</span>
            <IconButton onClick={() => setDateOpen(true)}>
              <FaPen color='#555' size={16} />
            </IconButton>
          </div>
        </div>
        <TableContainer component={Paper} className='table-container'>
          <Table>
            <TableHead className='table-header'>
              <TableRow>
                <TableCell align="left" className='table-head-title'>
                  Item
                </TableCell>
                <TableCell align="left" className='table-head-title'>
                  Quantity
                </TableCell>
                <TableCell align="left" className='table-head-title'>
                  Price
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell align="left" className='table-body-text' >NFT Request</TableCell>
                <TableCell align="left" className='table-body-text'>{invoice.nftUsage || 0}</TableCell>
                <TableCell align="left" className='table-body-text'>${invoice.nftUsagePrice || 0}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left" className='table-body-text'>Token Request</TableCell>
                <TableCell align="left" className='table-body-text'>{invoice.tokenUsage || 0}</TableCell>
                <TableCell align="left" className='table-body-text'>${invoice.tokenUsagePrice || 0}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left" className='table-body-text'>Transaction Request</TableCell>
                <TableCell align="left" className='table-body-text'>{invoice.transactionUsage || 0}</TableCell>
                <TableCell align="left" className='table-body-text'>${invoice.transactionUsagePrice || 0}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left" className='table-body-text'>Policy Request</TableCell>
                <TableCell align="left" className='table-body-text'>{invoice.policyUsage || 0}</TableCell>
                <TableCell align="left" className='table-body-text'>$0</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left" className='table-body-text'>Foundation Registration  Request</TableCell>
                <TableCell align="left" className='table-body-text'>{invoice.foundationUsage || 0}</TableCell>
                <TableCell align="left" className='table-body-text'>$0</TableCell>
              </TableRow>
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell className='bill-total'>
                  <div>
                    Total Amount
                  </div>
                  <div> ${invoice.totalUsagePrice || 0}</div>
                </TableCell>
              </TableRow>
              <TableRow className='last-row'>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell>
                  <Button className='view-invoice-btn' onClick={() => setOpen(true)}>View Invoice <FaEye color='white' /></Button>
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
        <Dialog open={open}>
          <DialogContent>
            <Invoice ref={componentRef} invoice={invoice} currentOrg={currentOrg.org} />
          </DialogContent>
          <DialogActions>
            <ReactToPrint
              trigger={() => <Button className='dialog-submit-btn'>Download PDF</Button>}
              content={() => componentRef.current}
            />
            <Button className='dialog-cancel-btn' onClick={() => setOpen(false)}>Cancel</Button>
          </DialogActions>
        </Dialog>
        <Dialog open={dateOpen}>
          <DialogTitle>Change Month & Year</DialogTitle>
          <FormControl sx={{ m: 1, width: 300 }}>
            <InputLabel>Select Month</InputLabel>
            <Select
              value={selectedMonth || 0}
              defaultValue={0}
              input={<OutlinedInput label="Select Month" />}
              onChange={handleChangeMonth}
            >
              {months.map((month, index) => (
                <MenuItem
                  key={index}
                  value={index}
                >
                  {month}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl sx={{ m: 1, width: 300 }}>
            <InputLabel>Select Month</InputLabel>
            <Select
              value={selectedYear || currentYear}
              defaultValue={0}
              input={<OutlinedInput label="Select Year" />}
              onChange={handleChangeYear}
            >
              {years.map((year) => (
                <MenuItem
                  key={year}
                  value={year}
                >
                  {year}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <DialogActions>
            <Button className='dialog-cancel-btn' onClick={() => setDateOpen(false)}>Cancel</Button>
            <Button className='dialog-submit-btn' onClick={() => handleSubmit()}>Submit</Button>
          </DialogActions>
        </Dialog>
      </div>
    </div >
  )
}

export default Billing