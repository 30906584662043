import React, { useState, useEffect } from 'react'
import {
    Dialog,
    DialogActions,
    Button
} from '@mui/material'
import { toast } from 'react-toastify'
import { useSelector, useDispatch } from 'react-redux'
import { EditOrgRequest, FindEditOrgRequest } from '../../store/actions/ManageOrgsActions'

export default function EditOrg(props) {
    const [name, setName] = useState(props.name)
    const [taxId, setTaxId] = useState(props.taxId)
    const [phone, setPhone] = useState(props.phone)
    const [submit, setSubmit] = useState(false)
    const orgId = useSelector(state => state.ManageOrgsList.currentOrg.org.id)
    const dispatch = useDispatch()
    useEffect(()=>{
        async function fetchMyAPI() {
            let response = await dispatch(FindEditOrgRequest(orgId))
           setSubmit(!response)
          }
          fetchMyAPI()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    const editOrg = () => {
        if (name.length >= 4) {
            if (taxId.length) {
                if (phone.length) {
                    dispatch(EditOrgRequest({ org_id: orgId, phone, name, taxId }))
                    setSubmit(true)
                }
                else {
                    toast.error('Phone Number is needed')
                }
            }
            else {
                toast.error("TaxId is required")
            }
        }
        else {
            toast.error('Name should be at least 4 character long')
        }

    }
    return (
        <Dialog open={props.openAdd} className='individual-dialog' onClose={()=> props.close()}>
            <div className='add-org-container'>
                <div className='add-org-container'>
                    <span>
                        Edit organization
                    </span>
                    {
                        submit ? <p>We have received your request <br />
                            please allow us some time to verify the details
                        </p>
                            :
                            <div className='text-field-container'>
                                <input placeholder='Organization name' type="text" value={name} onChange={(e) => { setName(e.target.value) }} className='add-org-input full-width' />
                                <input placeholder='Organization TIN' type="text" value={taxId} onChange={(e) => { setTaxId(e.target.value) }} className='add-org-input full-width' />
                                <input placeholder='Organization phone Number' type='tel' value={phone} onChange={(e) => { setPhone(e.target.value) }} className='add-org-input full-width' />
                                <p className='helper-text'>Taxpayer Identification Number (TIN) is a nine-digit number that always begins with the number 9 and is in the following format: 9xx-xx-xxxx.</p>
                            </div>
                    }
                </div>
            </div>
            <DialogActions>
                {!submit && <Button className='dialog-submit-btn' onClick={() => editOrg()}>Submit</Button>}
                <Button className='dialog-cancel-btn' onClick={() => props.close()}>Close</Button>
            </DialogActions>
        </Dialog>
    )
}
