import {
    Card,
    Button,
    TableContainer,
    Table,
    TableHead,
    Paper,
    TableCell,
    TableRow,
    TableBody,
    Tooltip,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    CircularProgress
} from '@mui/material'
import React from 'react'
import { toast, ToastContainer } from 'react-toastify'
import { connect } from 'react-redux'
import { getOrgById } from '../../store/actions/ManageOrgsActions'
import { revokeToken, revokePubNubToken } from '../../store/actions/AuthActions'
import { TopBar } from '../TopBar/TopBar'
import './IndividualOrg.css'
import '../Orgs/Orgs.css'
import { useParams } from 'react-router'
import { FaTrash } from 'react-icons/fa'
import {
    MdOutlineCorporateFare,
    MdOutlineTv,
    MdPermIdentity,
    MdVpnKey,
    MdCreditCard,
    MdAccountBalanceWallet,
    MdEdit
} from 'react-icons/md'
import QRCode from "react-qr-code";
import { getAuth, sendEmailVerification, onAuthStateChanged } from 'firebase/auth'
import AddMember from './AddMember'
import AddPubNubToken from './AddPubNubToken'
import AddAPIToken from './AddAPIToken'
import EditOrg from '../EditOrg/EditOrg'

export function withRouter(Children) {
    return (props) => {
        const match = { params: useParams() };
        return <Children {...props} match={match} />
    }
}

export class IndividualOrg extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            openPubNubRevoke: false,
            openRevoke: false,
            revokeTokenId: null,
            verified: false,
            loading: false,
            openEdit: false
        }
    }
    sendVerification = async () => {
        const auth = getAuth()
        await sendEmailVerification(auth.currentUser)
        toast.success('Verification Email Sent. Please check your email')
    }
    componentDidMount() {
        document.title = "Blockery Web App || Organization"
        this.setState({ loading: true })
        const auth = getAuth()
        onAuthStateChanged(auth, (user) => {
            if (user) {
                if (auth.currentUser.emailVerified) {
                    this.setState({
                        verified: true,
                        loading: false
                    })
                }
                else {
                    this.setState({
                        verified: false,
                        loading: false
                    })
                }
            }
            else {
                window.location.href = '/'
            }
        })
    }
    openRevoke = (id) => {
        this.setState({ openRevoke: true, revokeTokenId: id })
    }
    openPubNubRevoke = (id) => {
        this.setState({ openPubNubRevoke: true, revokeTokenId: id })
    }
    closeRevoke = () => {
        this.setState({ openRevoke: false, revokeTokenId: null })
    }
    closePubNubRevoke = () => {
        this.setState({ openPubNubRevoke: false, revokeTokenId: null })
    }
    confirmRevoke = () => {
        this.props.revokeToken(this.state.revokeTokenId).then(() => {
            this.props.getOrgById(this.props.state.currentOrg.org.id)
        })
        toast.success('Revoked Successfully')
        this.closeRevoke()
    }
    confirmPubNubRevoke = () => {
        this.props.revokePubNubToken(this.state.revokeTokenId).then(() => {
            this.props.getOrgById(this.props.state.currentOrg.org.id)
        })
        toast.success('Revoked Successfully')
        this.closePubNubRevoke()
    }
    copyPunNubChannel = () => {
        navigator.clipboard.writeText(`${this.props.state.currentOrg.org.id}_request_results`)
        toast.success('Copied to Clipboard')
    }
    copyPunNubUUID = () => {
        navigator.clipboard.writeText('e26ef6b0-c931-11ec-9d64-0242ac120002')
        toast.success('Copied to Clipboard')
    }
    copyPunNubSubScribeKey = () => {
        navigator.clipboard.writeText(process.env.REACT_APP_PUBNUB_SUB_KEY)
        toast.success('Copied to Clipboard')
    }
    copyWalletAdd = () => {
        navigator.clipboard.writeText(this.props.state.currentOrg.org.walletAddress)
        toast.success('Copied to Clipboard')
    }
    tokensPubnub = () => {
        return (
            <TableContainer component={Paper} className='table-container'>
                <Table>
                    <TableHead className='table-header'>
                        <TableRow>
                            <TableCell align="center" className='table-head-title'>Name</TableCell>
                            <TableCell align="center" className='table-head-title'>Created at</TableCell>
                            <TableCell align="center" className='table-head-title'>Status</TableCell>
                            <TableCell align="center" className='table-head-title'>Revoke</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.props.state.currentOrg.pubnubtokens.map((token, index) => {
                            return (
                                <TableRow key={index}>
                                    <TableCell align="center" className='table-body-text'>{token.name}</TableCell>
                                    <TableCell align="center" className='table-body-text'>{new Date(token.created_at.seconds * 1000).toLocaleDateString("en-US")}</TableCell>
                                    <TableCell align="center" className='table-body-text'>{token.status}</TableCell>
                                    <TableCell align="center">
                                        {
                                            token.status === 'active' ? (
                                                <Tooltip title="Revoke token">
                                                    <IconButton variant="contained" onClick={() => this.openPubNubRevoke(token.id)}>
                                                        <FaTrash color='red' />
                                                    </IconButton>
                                                </Tooltip>
                                            ) :
                                                <Tooltip title="Revoked">
                                                    <IconButton variant="contained">
                                                        <FaTrash />
                                                    </IconButton>
                                                </Tooltip>
                                        }
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }
    tokens = () => {
        return (
            <TableContainer component={Paper} className='table-container'>
                <Table>
                    <TableHead className='table-header' >
                        <TableRow>
                            <TableCell align="center" className='table-head-title'>Name</TableCell>
                            <TableCell align="center" className='table-head-title'>Created at</TableCell>
                            <TableCell align="center" className='table-head-title'>Last Digits</TableCell>
                            <TableCell align="center" className='table-head-title'>Status</TableCell>
                            <TableCell align="center" className='table-head-title'>Revoke</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.props.state.currentOrg.tokens.map((token, index) => {
                            return (
                                <TableRow key={index}>
                                    <TableCell align="center" className='table-body-text'>{token.name}</TableCell>
                                    <TableCell align="center" className='table-body-text'>{new Date(token.created_at.seconds * 1000).toLocaleDateString("en-US")}</TableCell>
                                    <TableCell align="center" className='table-body-text'>{token.lastDigits}</TableCell>
                                    <TableCell align="center" className='table-body-text'>{token.status}</TableCell>
                                    <TableCell align="center">
                                        {
                                            token.status === 'active' ? (
                                                <Tooltip title="Revoke token">
                                                    <IconButton variant="contained" onClick={() => this.openRevoke(token.id)}>
                                                        <FaTrash color='red' />
                                                    </IconButton>
                                                </Tooltip>
                                            ) :
                                                <Tooltip title="Revoked">
                                                    <IconButton variant="contained">
                                                        <FaTrash />
                                                    </IconButton>
                                                </Tooltip>
                                        }
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }
    membersTable = () => {
        return (
            <TableContainer component={Paper} className='table-container'>
                <Table>
                    <TableHead className='table-header'>
                        <TableRow>
                            <TableCell align="center" className='table-head-title'>Member Name</TableCell>
                            <TableCell align="center" className='table-head-title'>Member Role</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.props.state.currentOrg.org.members.map((member, index) => {
                            return (
                                <TableRow key={index}>
                                    <TableCell align="center" className='table-body-text'>{member.displayName}</TableCell>
                                    <TableCell align="center" className='table-body-text'>{member.role}</TableCell>

                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }
    render() {
        return (
            <>
                <ToastContainer limit={3} />
                <TopBar />{
                    this.state.loading ? <div className='add-org-container'><CircularProgress size={100} /></div> :
                        this.state.verified ? (
                            this.props.state.currentOrg.org ?
                                (<>
                                    <div className='org-div'>
                                        <div className='org-details'>
                                            <div className='org-name'>
                                                <span className='orgs-card-header' style={{ marginBottom: 16 }}>About Organization
                                                    <IconButton onClick={() => this.setState({ openEdit: true })}>
                                                        <MdEdit />
                                                    </IconButton>
                                                </span>
                                                <Card className='org-details-card'>
                                                    <span className='org-details-card-header'>Organization name</span>
                                                    <div className='org-details-card-right-container'>
                                                        <span className='org-details-card-detail'>{this.props.state.currentOrg.org.name}</span>
                                                        <MdOutlineCorporateFare size={24} style={{ marginLeft: 16 }} />
                                                    </div>
                                                </Card>
                                                <Tooltip title='Click to copy' onClick={() => this.copyPunNubChannel()}>
                                                    <Card className='org-details-card'>
                                                        <span className='org-details-card-header'>PunNub Channel</span>
                                                        <div className='org-details-card-right-container'>
                                                            <span className='org-details-card-detail'>{`${this.props.state.currentOrg.org.id}_request_results`}</span>
                                                            <MdOutlineTv size={24} style={{ marginLeft: 16 }} />
                                                        </div>
                                                    </Card>
                                                </Tooltip>
                                                <Tooltip title="Click to copy" onClick={() => this.copyPunNubUUID()} >
                                                    <Card className='org-details-card'>
                                                        <span className='org-details-card-header'>PunNub UUID</span>
                                                        <div className='org-details-card-right-container'>
                                                            <span className='org-details-card-detail'> e26ef6b0-c931-11ec-9d64-0242ac120002</span>
                                                            <MdPermIdentity size={24} style={{ marginLeft: 16 }} />
                                                        </div>
                                                    </Card>
                                                </Tooltip>
                                                <Tooltip title='Click to copy' onClick={() => this.copyPunNubSubScribeKey()}>
                                                    <Card className='org-details-card'>
                                                        <span className='org-details-card-header'>PubNub Subscribe Key</span>
                                                        <div className='org-details-card-right-container'>
                                                            <span className='org-details-card-detail'> {process.env.REACT_APP_PUBNUB_SUB_KEY} </span>
                                                            <MdVpnKey size={24} style={{ marginLeft: 16 }} />
                                                        </div>
                                                    </Card>
                                                </Tooltip>
                                                <Tooltip title='Click to copy' onClick={() => this.copyWalletAdd()}>
                                                    <Card className='org-details-card'>
                                                        <span className='org-details-card-header'> Wallet address</span>
                                                        <div className='org-details-card-right-container'>
                                                            <span className='org-details-card-detail truncate'> {this.props.state.currentOrg.org.walletAddress} </span>
                                                            <MdAccountBalanceWallet size={24} style={{ marginLeft: 16 }} />
                                                        </div>
                                                    </Card>
                                                </Tooltip>
                                                <Card className='org-details-card'>
                                                    <span className='org-details-card-header'> ADA balance</span>
                                                    <div className='org-details-card-right-container'>
                                                        <span className='org-details-card-detail'> {this.props.state.wallet.controlled_amount ? this.props.state.wallet.controlled_amount / 1000000 : 0} </span>
                                                        <MdCreditCard size={24} style={{ marginLeft: 16 }} />
                                                    </div>
                                                </Card>
                                            </div>
                                            <Card style={{ background: 'white', padding: '16px', marginLeft: "auto", marginRight: "auto", maxWidth: 300, border: '3px solid #000000', height: '50%', marginTop: '100px' }}>
                                                {
                                                    this.props.state.currentOrg.org.walletAddress ?
                                                        <QRCode title={this.props.state.currentOrg.org.walletAddress} value={this.props.state.currentOrg.org.walletAddress} />
                                                        :
                                                        <CircularProgress />
                                                }
                                                <div className='org-details-card-header send-ada'>
                                                    Send ADA to Wallet Address
                                                </div>
                                            </Card>

                                        </div>
                                        <div className="org-table-header-container">
                                            <h3 className="orgs-card-header">Member List</h3>
                                            <AddMember orgId={this.props.state.currentOrg.org.id} orgName={this.props.state.currentOrg.org.name} />
                                        </div>
                                        {this.membersTable()}
                                        <div className="org-table-header-container">
                                            <h3 className="orgs-card-header">API Token List</h3>
                                            <AddAPIToken orgId={this.props.state.currentOrg.org.id} />
                                        </div>
                                        {this.tokens()}
                                        <div className="org-table-header-container">
                                            <h3 className="orgs-card-header">PubNub Token List</h3>
                                            <AddPubNubToken orgId={this.props.state.currentOrg.org.id} />
                                        </div>
                                        {this.tokensPubnub()}
                                        <Dialog open={this.state.openRevoke} className="individual-dialog">
                                            <DialogTitle> Revoke Token ? </DialogTitle>
                                            <DialogContent>
                                                <DialogContentText>
                                                    Are you sure you want to revoke this token?
                                                    Any Application that is using this token will not be able to access the resources.
                                                </DialogContentText>
                                            </DialogContent>
                                            <DialogActions>
                                                <Button className='dialog-submit-btn' onClick={() => this.confirmRevoke()}>
                                                    Revoke
                                                </Button>
                                                <Button className='dialog-cancel-btn' onClick={() => this.closeRevoke()}>
                                                    Cancel
                                                </Button>
                                            </DialogActions>
                                        </Dialog>
                                        <Dialog open={this.state.openPubNubRevoke} className="individual-dialog">
                                            <DialogTitle> Revoke Token ? </DialogTitle>
                                            <DialogContent>
                                                <DialogContentText>
                                                    Are you sure you want to revoke this token?
                                                    Any Application that is using this token will not be able to access the resources.
                                                </DialogContentText>
                                            </DialogContent>
                                            <DialogActions>
                                                <Button className='dialog-submit-btn' onClick={() => this.confirmPubNubRevoke()} color="primary">
                                                    Revoke
                                                </Button>
                                                <Button className='dialog-cancel-btn' onClick={() => this.closePubNubRevoke()}>
                                                    Cancel
                                                </Button>
                                            </DialogActions>
                                        </Dialog>
                                    </div>
                                    <EditOrg
                                        openAdd={this.state.openEdit}
                                        close={() => this.setState({ openEdit: false })}
                                        name={this.props.state.currentOrg.org.name}
                                        taxId={this.props.state.currentOrg.org.tax_id}
                                        phone={this.props.state.currentOrg.org.phone}
                                    />
                                </>
                                ) : null
                        ) : <>
                            <Dialog open={!this.state.Verified}>
                                <DialogTitle>Verify your email.</DialogTitle>
                                <DialogContent>
                                    <p>Please verify your email to continue</p>
                                    <p>If you have already verified please refresh or try logging in again</p>
                                </DialogContent>
                                <DialogActions>
                                    <Button variant='contained' onClick={() => window.location.href = '/'}>Login</Button>
                                    <Button variant='contained' onClick={() => this.sendVerification()}>Resend Verification Email</Button>
                                </DialogActions>
                            </Dialog>
                        </>
                }
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        state: state.ManageOrgsList
    }
}

const mapDispatchToProps = dispatch => ({
    getOrgById: (orgId) => dispatch(getOrgById(orgId)),
    revokeToken: (tokenId) => dispatch(revokeToken(tokenId)),
    revokePubNubToken: (tokenId) => dispatch(revokePubNubToken(tokenId)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(IndividualOrg))