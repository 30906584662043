import Login from "./components/Login/Login";
import SignUp from "./components/SignUp/SignUp";
import ForgotPassword from "./components/ForgotPassword/ForgotPassword";
import IndividualOrg from "./components/IndividualOrg/IndividualOrg";
import Dashboard from "./components/Dashboard/Dashboard";
// import AddOrgs from "./components/Orgs/Orgs";
import AcceptInvitation from "./components/AcceptInvitation/AcceptInvitation";
import GetHelp from "./components/GetHelp/GetHelp";
import ReDoc from "./components/ReDoc/ReDoc";
import Billing from "./components/Billing/Billing";
import Invoice from "./components/Billing/Invoice";
import NotFound from "./components/NotFound/NotFound";
import { BrowserRouter, Routes, Route } from "react-router-dom";
// eslint-disable-next-line no-unused-vars
import firebaseApp from './config/firebase';
import ReactGAComponent from "./components/ReactGA/ReactGA";



function App() {
  return (
    <BrowserRouter>
      <ReactGAComponent />
      <div>
        <Routes>
          <Route path="/" exact element={<Login />} />
          <Route path="/:inviteId" exact element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path='/forgot-password' element={<ForgotPassword />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path='/organization' element={<IndividualOrg />} />
          <Route path="/accept-invitation/:inviteToken" element={<AcceptInvitation />} />
          <Route path="/help" element={<GetHelp />} />
          <Route path="/invoice" element={<Invoice />} />
          <Route path="/billing" element={<Billing />} />
          {/* <Route path="/orgs/:id" element={<IndividualOrg />} /> */}
          <Route path="/docs/public-api/v1" element={<ReDoc />} />
          <Route path='*' element={<NotFound />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
