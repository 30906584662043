import React, { useState, useEffect, } from 'react'
import { Button } from '@mui/material'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { validateEmail } from '../../utils/utils';
import 'react-toastify/dist/ReactToastify.css';
import './login.css'
import { connect } from 'react-redux';
import { login, connectWithGoogle, connectWithGithub } from '../../store/actions/AuthActions'
import GoogleLogo from "../../assets/google-logo.png"
import GitLogo from "../../assets/git-logo.png"
import AuthRight from '../AuthRight/AuthRight';

function Login({ state, connectWithGoogle, connectWithGithub, storelogin }) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const {inviteId}= useParams()
    const nav = useNavigate();
    useEffect(() => {
        if (state.isAuthenticated && !state.loading) {
            nav('/dashboard');
        }
        else{
            nav('/')
        }
        if(inviteId){
            sessionStorage.setItem("inviteId", inviteId)
            nav('/')
        }
    }, [state.isAuthenticated, inviteId, nav, state.loading]);
    useEffect(()=>{
        document.title ="Blockery Web App || Login"
    })
    const login = () => {
        if (email && password) {
            if (!validateEmail(email)) {
                toast.error('Please enter a valid email')
            }
            else if (password.length < 6) {
                toast.error('Password must be at least 6 characters')
            }
            else {
                storelogin({
                    email: email,
                    password: password
                })
            }
        }
        else {
            toast.error('Please fill out all fields')
        }
    }
    return (
        <div className='auth-container'>
            <ToastContainer pauseOnHover autoClose={2000} />
            <AuthRight />
            <div data-aos="zoom-in" data-aos-duration="500" className='auth-container-left' >
                <h3>Hello Again!</h3>
                <p>Welcome Back</p>
                <input placeholder='Email' onChange={(e) => setEmail(e.target.value)} className='auth-input'></input>
                <input placeholder='Password' onChange={(e) => setPassword(e.target.value)} type="password" className='auth-input' />
                <Button onClick={login} className='auth-btn'>Login</Button>
                <p>Don't have an account? <Link to="/signup" style={{ textDecoration: "none" }}><Button>SignUp</Button></Link></p>
                <Button onClick={() => connectWithGoogle()} className="auth-btn">
                    <img src={GoogleLogo} alt="google-logo" className='google-logo' />
                    Login with Google
                </Button>
                <Button onClick={() => connectWithGithub()} className="auth-btn">
                    <img src={GitLogo} alt="google-logo" className='google-logo' />
                    Login with GitHub
                </Button>
                <Link to={'/forgot-password'} style={{ textDecoration: "none" }}><Button>Forgot Password?</Button></Link>
            </div>
        </div>
    )
}
const mapStateToProps = state => {
    return {
        state: state.AuthServicesList
    };
};

const mapDispatchToProps = dispatch => {
    return {
        storelogin: (body) => {
            dispatch(login(body));
        },
        connectWithGoogle: () => {
            dispatch(connectWithGoogle());
        },
        connectWithGithub: () => {
            dispatch(connectWithGithub());
        }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Login);
