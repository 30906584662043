const initialState = {
  user: {},
  isAuthenticated: false,
  loading : false,
  token: {},
};
const AuthServicesReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LoginSuccess":
      return {
        ...state,
        user: action.payload,
        isAuthenticated: true,
      };
    case "LogoutSuccess":
      return {
        ...state,
        user: {},
        isAuthenticated: false,
      };
    case "LoginError":
      return {
        ...state,
        user: {},
        isAuthenticated: false,
      };
      case "SignOutPending":
        return {
        ...state,
        loading : true
        };
    case "SignOut":
      return {
        user: {},
        isAuthenticated: false,
        loading: false
      };
    case "SetToken":
      return {
        ...state,
        token: action.payload,
      };
    default:
      return state;
  }
};
export default AuthServicesReducer;
