import React from 'react'
import {
  useLocation,
  useNavigate
} from "react-router-dom";
import { Button, Drawer, List, ListItem, Toolbar } from '@mui/material'
import { FaMoneyBillAlt } from 'react-icons/fa'
import { SiGoogleanalytics } from 'react-icons/si'
import { BsBank2, BsFillQuestionCircleFill } from 'react-icons/bs'
import './SideNav.css'

export const SideBar = (props) => {
  const { pathname } = useLocation();
  const nav = useNavigate()
  return (
    <Drawer
      variant="persistent"
      anchor="left"
      open={props.open}
      elevation={0}
      PaperProps={{
        sx: {
          background: "linear-gradient(180deg, #0575E6 0%, #02298A 84.79%, #021B79 100%);",
          width: 280
        }
      }}
    >
      <Toolbar />
      <Toolbar />
      <List className='list-container'>
        <ListItem style={{ justifyContent: 'center' }}>
          {
            pathname === '/dashboard' ?
              <Button className='side-nav-btn-active'>
                <SiGoogleanalytics />
                Usage
              </Button>
              : <Button onClick={() => nav('/dashboard')} variant='outlined' className='side-nav-btn'>
                <SiGoogleanalytics />
                Usage
              </Button>
          }

        </ListItem>
        <ListItem style={{ justifyContent: 'center' }}>
          {
            pathname === '/organization' ?
              <Button className='side-nav-btn-active'>
                <BsBank2 />
                Build
              </Button>
              :
              <Button onClick={() => nav('/organization')} variant='outlined' className='side-nav-btn'>
                <BsBank2 />
                Build
              </Button>
          }

        </ListItem>
        <ListItem style={{ justifyContent: 'center' }}>
          {
            pathname === '/billing' ?
              <Button className='side-nav-btn-active'>
                <FaMoneyBillAlt />
                Billing
              </Button>
              :
              <Button onClick={() => nav('/billing')} variant='outlined' className='side-nav-btn'>
                <FaMoneyBillAlt />
                Billing
              </Button>
          }
        </ListItem>
        <ListItem style={{ justifyContent: 'center' }}>
          {
            pathname === '/help' ?
              <Button className='side-nav-btn-active'>
                <BsFillQuestionCircleFill />
                Get Help
              </Button>
              :
              <Button onClick={() => nav('/help')} variant='outlined' className='side-nav-btn'>
                <BsFillQuestionCircleFill />
                Get Help
              </Button>
          }
        </ListItem>

      </List>
    </Drawer>
  )
}

export default SideBar