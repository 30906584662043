import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import TopBar from '../TopBar/TopBar'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import { getOrgs } from '../../store/actions/ManageOrgsActions'
import CompanyDashboard from './CompanyDashboard'
import './Dashboard.css'

export const Dashboard = () => {
    const nav = useNavigate();
    const dispatch = useDispatch()
    const state = useSelector((state) => state.ManageOrgsList.orgs)
    useEffect(() => {
        const auth = getAuth()
        onAuthStateChanged(auth, (user) => {
            if (!user) {
                nav('/')
            }
            if (sessionStorage.getItem('inviteToken')) {
                nav('/accept-invitation/' + sessionStorage.getItem('inviteToken'));
            }
        })
        dispatch(getOrgs())
    }, [dispatch, nav])
    useEffect(()=>{
        document.title ="Blockery Web App || Dashboard"
    })
    return (
        <div>
            <TopBar />
            <div className="dashboard-container">
                {
                    state.length > 0 ? <CompanyDashboard/> : null
                        
                }
            </div>
        </div>
    )
}

export default Dashboard