export const  anonymousEmailList = [
    'mailinator.com',
    'guerrillamail.com',
    'spam4.me',
    'spam.la',
    'spamgourmet.com',
    'spam.su',
    'spam.usa.cc',
    'spam4.me',
    'spam.la',
    'proton.me',
    'protonmail.ch',
    'protonmail.com',
    'pm.me',
    'fake-box.com',
    'fakeinbox.com',
    'opentrash.com',
    'you-spam.com',

];
