const initialState = {
    orgs: [],
    currentOrg: {},
    invoice: {},
    wallet: {},
    loading: false,
    pubNubtoken: '',
    currentBill: {},
    previousBill: {}
};
const MangeOrgsReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'AddSuccess':
            return {
                ...state,
            };
        case 'AddError':
            return {
                ...state,
            };
        case 'GetSuccess':
            return {
                ...state,
                orgs: action.payload,
            };
        case 'InvitationSuccess':
            return {
                ...state,
            };
        case 'SetCurrentOrg':
            return {
                ...state,
                currentOrg: action.payload,
            };
        case 'WalletSuccess':
            return {
                ...state,
                wallet: action.payload,
            };
        case 'Loading':
            return {
                ...state,
                loading: action.payload,
            };
        case 'TokenSuccess':
            return {
                ...state,
                pubNubToken: action.payload,
            };
        case 'CurrentBill':
            return {
                ...state,
                currentBill: action.payload
            }
        case 'PreviousBill':
            return {
                ...state,
                previousBill: action.payload
            }
        case 'invoice':
            return {
                ...state,
                invoice: action.payload
            }

        default:
            return state;
    }
};
export default MangeOrgsReducer;