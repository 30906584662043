import React, { useState, useEffect } from 'react'
import TopBar from '../TopBar/TopBar'
import {
    Button,
    TextField,
    TextareaAutosize,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions
} from '@mui/material'
import { Link } from 'react-router-dom'
import { BsFillTelephoneFill } from 'react-icons/bs'
import { toast, ToastContainer } from 'react-toastify'
import { getHelp } from '../../store/actions/utilActions'
import { useDispatch } from 'react-redux'
import './GetHelp.css'

function GetHelp() {
    const [subject, setSubject] = useState('')
    const [message, setMessage] = useState('')
    const [phone, setPhone] = useState('')
    const [open, setOpen] = useState(false)
    const dispatch = useDispatch()

    useEffect(()=>{
        document.title ="Blockery Web App || Get Help"
    })

    const submit = () => {
        if (subject.length < 10 && message.length < 10) {
            toast.warning('Subject and Message must have at least 10 characters')
        }
        else {
            setOpen(true)
        }
    }
    const request = () => {
        if (phone.length < 10) {
            toast.warning('Phone No must have at least 10 characters')
        }
        else {
            dispatch(getHelp({ phone, subject, message }))
            setOpen(false)
        }
    }
    return (
        <div>
            <ToastContainer />
            <TopBar />
            <div className='get-help-container'>
                <span className='want-help-text'>Want Help?</span>
                <span className='want-help-subtitle'>
                    Check out our  <Link to={'/docs/public-api/v1'} >api documentation</Link> and <a href='https://docs.blockery.io/' target="_blank" rel="noopener noreferrer">knowledge base</a> for guidance on getting started. If you're still having trouble, reach out below! We'll respond within a business day.
                </span>
                <TextField className='get-help-input' placeholder='Enter Subject' variant="standard"
                    InputProps={{
                        disableUnderline: true,
                    }} onChange={(e) => setSubject(e.target.value)} />
                <TextareaAutosize className='get-help-input' placeholder='Enter Message'
                    style={{ height: 150, padding: 24 }} maxRows={4} onChange={(e) => setMessage(e.target.value)} />
                <Button className='auth-btn' onClick={submit}>Request a call back <BsFillTelephoneFill style={{ marginLeft: 5 }} /></Button>
            </div>
            <>
                <Dialog open={open}>
                    <DialogTitle>Request a call</DialogTitle>
                    <DialogContent>
                        <span className='want-help-subtitle'>
                            We will try to contact you at the number
                            you will enter asap.
                        </span>
                        <TextField className='get-help-input' placeholder='Enter Phone No.' variant="standard" type='number'
                            style={{
                                width: 250
                            }}
                            InputProps={{
                                disableUnderline: true,
                            }} onChange={(e) => setPhone(e.target.value)} />
                    </DialogContent>
                    <DialogActions>
                        <Button className='dialog-submit-btn' onClick={() => request()}>Request</Button>
                        <Button className='dialog-cancel-btn' onClick={() => setOpen(false)}>Cancel</Button>
                    </DialogActions>
                </Dialog>
            </>
        </div>
    )
}

export default GetHelp