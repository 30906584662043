import React, { useState, useEffect } from 'react'
import { Button } from '@mui/material'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { validateEmail } from '../../utils/utils';
import { Link } from 'react-router-dom';
import GoogleLogo from "../../assets/google-logo.png"
import GitLogo from "../../assets/git-logo.png"
import { connect } from 'react-redux';
import { connectWithGoogle, connectWithGithub, forgot } from '../../store/actions/AuthActions';
import { useNavigate } from 'react-router-dom';
import { anonymousEmailList } from '../../utils/anonymous';
import AuthRight from '../AuthRight/AuthRight';


function ForgotPassowrd({ state, connectWithGoogle, connectWithGithub, forgotStore }) {
    const [email, setEmail] = useState('');
    const nav = useNavigate();
    useEffect(() => {
        if (state.isAuthenticated) {
            nav('/dashboard');
        }
    }, [state, nav]);
    useEffect(()=>{
        document.title ="Blockery Web App || Forget Password"
    })
    const forgot = () => {
        if (email) {
            if (!validateEmail(email)) {
                toast.error('Please enter a valid email')
            }
            else {
                // find email domain
                const emailDomain = email.split('@');
                if (anonymousEmailList.includes(emailDomain[emailDomain.length - 1])) {
                    toast.error('Please enter a valid email')
                } else {
                    forgotStore({ email })
                }
            }
        }
        else {
            toast.error('Please fill out all fields')
        }
    }
    return (
        <div className='auth-container'>
            <ToastContainer pauseOnHover autoClose={2000} />
            <AuthRight />
            <div data-aos="zoom-in" data-aos-duration="500" className='auth-container-left' >
                <h3>Hello Again!</h3>
                <p>Enter your email to get started</p>
                <input placeholder='Email' type="email" className='auth-input' onChange={(e) => setEmail(e.target.value)}></input>
                <Button className='auth-btn' onClick={() => forgot()}>Send Rest Link</Button>
                <p>Already have an account? <Link to="/login" style={{ textDecoration: "none" }}><Button>Login</Button></Link></p>
                <Button onClick={() => connectWithGoogle()} className="auth-btn">
                    <img src={GoogleLogo} alt="google-logo" className='google-logo' />
                    Login with Google
                </Button>
                <Button onClick={() => connectWithGithub()} className="auth-btn">
                    <img src={GitLogo} alt="google-logo" className='google-logo' />
                    Login with GitHub
                </Button>
            </div>
        </div>
    )
}
const mapStateToProps = state => {
    return {
        state: state.AuthServicesList
    }
}
const mapDispatchToProps = dispatch => {
    return {
        connectWithGoogle: () => dispatch(connectWithGoogle()),
        connectWithGithub: () => dispatch(connectWithGithub()),
        forgotStore: (body) => dispatch(forgot(body))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassowrd)