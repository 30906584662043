import { addDoc, collection, getFirestore } from "firebase/firestore"
import { getAuth } from "firebase/auth";
import {toast} from 'react-toastify'

export const getHelp = (body)=>{
    return async (dispatch)=>{
        const auth = getAuth();
        const user =  auth.currentUser;
        const subject = `Hi ${user.displayName} has asked for help . ${body.subject}`
        const message = ` Message = ${body.message} \n Phone No. = ${body.phone}`
        // create invitation for user
        addDoc(collection(getFirestore(), "mail"), {
            to: 'support@blockery.io',
            message: {
                subject: subject,
                html: message
              }
        }).then(res => {
            toast.success('Request sent successfully.', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true
            });
        })
        .catch(err =>{
            toast.error('Something went Wrong. Please try again.')
        })
    }
}