import React, { useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Card } from '@mui/material'
import { max } from 'lodash'
import * as d3 from 'd3'
import { getBillableServices } from '../../store/actions/ManageOrgsActions'

function CompanyDashboard() {

    const svgRef = useRef()
    const dispatch = useDispatch()
    const currentBill = useSelector((state) => state.ManageOrgsList.currentBill)
    const previousBill = useSelector((state) => state.ManageOrgsList.previousBill)
    const currentOrg = useSelector((state) => state.ManageOrgsList.currentOrg.org)
    const dates = Array.from(Array(30).keys())

    useEffect(() => {
        dispatch(getBillableServices(currentOrg))
    }, [currentOrg, dispatch])
    if (currentBill.dayWiseUsage) {
        const width = 1100
        const height = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0) * 0.4

        //create SVG
        const svg = d3.select(svgRef.current)
            .attr('width', width)
            .attr('height', height)
            .style('margin-top', 50)
            .style('margin-left', 50)
            .style('overflow', 'visible')

        // SetUp X-Axis
        const xScale = d3.scaleLinear()
            .domain([0, dates.length])
            .range([0, width])

        const xAxis = d3.axisBottom(xScale)
            .ticks(dates.length)
            .tickFormat(i => i + 1)

        // SetUp Y-Axis
        const yScale = d3.scaleLinear()
            .domain([0, max(currentBill.dayWiseUsage) || 10])
            .range([height, 0])
        const yAxis = d3.axisLeft(yScale)
            .ticks(10)

        const generateScaledLine = d3.line()
            .x((d, i) => xScale(i))
            .y(yScale)
            .curve(d3.curveMonotoneX)

        svg.append('g')
            .call(xAxis)
            .attr('transform', `translate(0, ${height})`)
            .attr('color', '#CFCFCF')

        svg.append('g')
            .call(yAxis)
            .attr('color', '#CFCFCF')

        svg.selectAll('.line')
            .data([currentBill.dayWiseUsage])
            .join('path')
            .attr('d', d => generateScaledLine(d))
            .attr('fill', 'none')
            .attr('stroke', '#48B4FF')
            .attr('stroke-width', '2px')

        if (previousBill.dayWiseUsage) {
            svg.selectAll('.line')
                .data([previousBill.dayWiseUsage])
                .join('path')
                .attr('d', d => generateScaledLine(d))
                .attr('fill', 'none')
                .attr('stroke', '#FFCD9E')
                .attr('stroke-width', '2px')
        }
    }
    return (
        <>
            <div className='analytics-card-container'>
                <Card className='analytics-card'>
                    <div className='analytics-card-title-container'>
                        <span className='analytics-card-title'>
                            NFTs created
                        </span>
                        <span className='analytics-card-subtitle'>
                            This month so far
                        </span>
                    </div>
                    <div className='analytics-card-title-container'>
                        <span className='analytics-card-title'>
                            {currentBill?.nftUsageCounter || 0}
                        </span>
                        <span className='analytics-card-subtitle'>
                            vs. {previousBill?.nftUsageCounter || 0} Previous month
                        </span>
                    </div>
                </Card>
                <Card className='analytics-card'>
                    <div className='analytics-card-title-container'>
                        <span className='analytics-card-title'>
                            Tokens created
                        </span>
                        <span className='analytics-card-subtitle'>
                            This month so far
                        </span>
                    </div>
                    <div className='analytics-card-title-container'>
                        <span className='analytics-card-title'>
                            {currentBill?.tokenUsageCounter || 0}
                        </span>
                        <span className='analytics-card-subtitle'>
                            vs. {previousBill?.nftUsageCounter || 0} Previous month
                        </span>
                    </div>
                </Card>
                <Card className='analytics-card'>
                    <div className='analytics-card-title-container'>
                        <span className='analytics-card-title'>
                            Transactions created
                        </span>
                        <span className='analytics-card-subtitle'>
                            This month so far
                        </span>
                    </div>
                    <div className='analytics-card-title-container'>
                        <span className='analytics-card-title'>
                            {currentBill?.transactionUsageCounter || 0}
                        </span>
                        <span className='analytics-card-subtitle'>
                            vs. {previousBill?.nftUsageCounter || 0} Previous month
                        </span>
                    </div>
                </Card>
            </div>
            <Card className='graph-container'>
                <svg ref={svgRef} />
                <div className='this-month-container'>
                    <div className='this-month-box'>
                    </div>
                    This month
                    <div className='last-month-box'>
                    </div>
                    Last month
                </div>
            </Card>
        </>
    )
}

export default CompanyDashboard
